import { useContext } from "react";
import { AuthContext } from "../context";
import { menleiApi } from "../api";
import Swal from "sweetalert2";

export const useAuthContext = () => {

    const { user, resetUser, setUser } = useContext(AuthContext);

    const checkAuthToken = async () => {

        const refresh_token = localStorage.getItem('refresh_token');

        if (!refresh_token) {
            return setUser(data => ({ ...data, status: 'not-authenticated' }))
        }

        try {

            const { data } = await menleiApi.get('/auth/refresh-token', {
                headers: {
                    'Authorization': `Bearer ${refresh_token}`
                }
            });

            // console.log(data)

            localStorage.setItem('token', data.token);

            onLogin({ name: data.user.name, uid: data.user.id, email: data.user.email })

        } catch (error) {

            console.log(error)
            startLogout();
        }
    }

    const startRegister = async ({ email, name, password }) => {

        onChecking();

        try {

            const { data } = await menleiApi.post('/auth/register', { email, name, password })

            localStorage.setItem('token', data.token);
            localStorage.setItem('refresh_token', data.refresh_token);

            const { user } = data;

            onLogin({ name: data.user.name, uid: data.user.id, email: data.user.email })

        } catch (error) {
            console.log(error)
            Swal.fire('', error.response.data?.errors?.email[0], 'error')

            startLogout()
        }
    }

    const startLogout = () => {

        localStorage.clear();
        resetUser()
        setUser(data => ({ ...data, status: 'not-authenticated' }))
    }

    const onChecking = () => {
        setUser(data => ({ ...data, status: 'checking' }))
    }

    const onLogin = ({ name, uid, email }) => {
        setUser(data => ({ ...data, name, uid, email, status: 'authenticated' }));
    }


    const startLogin = async ({ email, password }) => {
        // console.log({ email, password })

        onChecking();

        try {
            const { data } = await menleiApi.post('/auth/login', { email, password })

            localStorage.setItem('token', data.token);
            localStorage.setItem('refresh_token', data.refresh_token);

            const { user } = data;

            onLogin({ name: user.name, uid: user.id });


        } catch (error) {

            startLogout();
            Swal.fire('', 'Combinación de contraseña y correo electrónico invalida', 'error')
            // setTimeout(() => {
            //     clearErrorMessage();
            // }, 10)
        }


    }

    return {
        checkAuthToken,
        startLogout,
        startLogin,
        startRegister,
        onChecking,
        onLogin,
        user,
        resetUser,
        setUser
    }
}