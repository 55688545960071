import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css'
import Swal from 'sweetalert2';
import { useAuthContext, useForm } from '../../hooks';

const registerFormFields = {
    email: '',
    password: '',
    password2: '',
    name: ''
}

export const RegisterPage = () => {

    const { email, password, name, password2, onInputChange, onResetForm } = useForm(registerFormFields)
    const { startRegister } = useAuthContext()

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== password2) {
            Swal.fire('Error sing up', 'The passwords are not equals', 'error');
            return;
        }

        startRegister({ email, name, password });

        onResetForm()


    }

    return (
        <div style={{
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}

        >
            <div className='col-md-5 col-lg-4 p-3' >
                <div className='mb-5 d-flex justify-content-center'>

                    <img
                        src='/img/LOGO-400PX.png'
                        className='img-fluid form__logo'
                        style={{
                            maxWidth: '200px'
                        }}
                    />
                </div>
                <div className='app__container w-100 p-5'>

                    <form onSubmit={handleSubmit} >
                        <h1 className='mb-3 fw-bold h2 '>Crear una cuenta</h1>
                        <div className='form-group mb-3'>
                            <label
                                htmlFor='email'
                            >
                                Correo electrónico
                            </label>
                            <input
                                type='email'
                                className='form-control '
                                id='email'
                                placeholder='name@example.com'
                                name='email'
                                value={email}
                                onChange={onInputChange}
                            />
                        </div>

                        <div className='form-group mb-3'>
                            <label
                                htmlFor='name'
                            >
                                Nombre de usuario
                            </label>
                            <input
                                type='text'
                                className='form-control '
                                id='name'
                                placeholder='Alejandro Llorente'
                                name='name'
                                value={name}
                                onChange={onInputChange}
                            />
                        </div>
                        <div className='form-group mb-3'>
                            <label
                                htmlFor='password'
                            >
                                Contraseña
                            </label>
                            <input
                                type='password'
                                className='form-control '
                                id='password'
                                placeholder='Password'
                                name='password'
                                value={password}
                                onChange={onInputChange}
                            />

                        </div>
                        <div className='form-group mb-3'>
                            <label
                                htmlFor='password'
                            >
                                Por favor, repita la contraseña
                            </label>
                            <input
                                type='password'
                                className='form-control '
                                id='password2'
                                placeholder='Por favor, repita la contraseña'
                                name='password2'
                                value={password2}
                                onChange={onInputChange}
                            />

                        </div>
                        <div className='mb-3'>
                            ¿Ya tiene una cuenta? <Link to='/auth/login' >Ingresar</Link>
                        </div>
                        <button
                            className='w-100 btn btn-lg btn-primary btn--purple'
                            type='submit'
                        >
                            Registrarse
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}