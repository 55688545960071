import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css'
import { useAuthContext, useForm, useGoogleAuth } from '../../hooks';

const loginFormFields = {
  email: '',
  password: ''
}


export const LoginPage = () => {

  const { email, password, onInputChange } = useForm(loginFormFields);
  const [ingresarConEmail, setIngresarConEmail] = useState(false)
  const { startLogin } = useAuthContext()
  const { login } = useGoogleAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    startLogin({ email, password });
  }


  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}

    >



      <div className='col-md-5 col-lg-4 p-3'>

        <div className='mb-5 d-flex justify-content-center'>

          <img
            src='/img/LOGO-400PX.png'
            className='img-fluid form__logo'
            style={{
              maxWidth: '200px'
            }}
          />
        </div>
        <div className='app__container w-100 p-5'>
          <h1 className='mb-3 fw-bold h2 '>Ingresar a Menlei</h1>
          <h4 className='fw-normal h6  mb-4'>Ingresá tu correo electrónico y contraseña para acceder</h4>

          {
            !ingresarConEmail
              ? (

                <div>
                  <div>

                    <button className='btn w-100 bg-light border mb-3' onClick={login}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google me-1" viewBox="0 0 16 16">
                        <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
                      </svg>
                      Ingresar con Google
                    </button>
                  </div>
                  <div>
                    <button
                      className='btn w-100 bg-light border'
                      onClick={() => setIngresarConEmail(true)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-1" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                      </svg> Ingresar con email
                    </button>
                  </div>

                  <div className='mt-3'>
                    ¿Todavía no estás registrado? <Link to='/auth/register' >Crear una cuenta</Link>
                  </div>
                </div>
              )
              : (

                <form onSubmit={handleSubmit} >
                  
                  <div className='form-group mb-3 '>
                    <label
                      htmlFor='email'
                    >
                      Correo electrónico
                    </label>
                    <input
                      type='email'
                      className='form-control '
                      id='email'
                      placeholder='name@example.com'
                      name='email'
                      onChange={onInputChange}
                      value={email}
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label
                      htmlFor='password'
                    >
                      Contraseña
                    </label>
                    <input
                      type='password'
                      className='form-control '
                      id='password'
                      placeholder='Password'
                      name='password'
                      onChange={onInputChange}
                      value={password}
                    />

                  </div>
                  <div className='mb-3'>
                    ¿Todavía no estás registrado? <Link to='/auth/register' >Crear una cuenta</Link>
                  </div>


                  <button
                    className='w-100 btn btn-lg btn--purple'
                    type='submit'
                  >
                    Ingresar
                  </button>
                </form>
              )
          }


        </div>
      </div>
    </div>
  )
}