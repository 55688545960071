import React, { useEffect, useState } from 'react'
import { mantecaApi } from '../api'

export const PrecioDelDolar = () => {

    const [precio, setPrecio] = useState('$0 ARS')

    const loadPrecio = async () => {
        try {
            const { data } = await mantecaApi.get(`/v1/price/USDT_ARS`)
            setPrecio('$' + Number(data.sell) + ' ARS')
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loadPrecio()
    }, [])

    return (
        <div className='app__container py-2 px-3  my-3 bg-light-green d-flex justify-content-between fw-bold '>
            <p className=' mb-0 '>
                Precio del dolar Menlei:
            </p>
            <span>{precio}</span>
        </div>
    )
}

