import React, { useEffect, useRef, useState } from 'react'
import { mantecaApi } from '../api'
import { useAuthContext } from '../hooks'
import DataTable from 'react-data-table-component'


export const Orders = () => {

    const tableRef = useRef()
    const [orders, setOrders] = useState([
        {
            "orderId": "210",
            "userId": "35",
            "coin": "ETH_ARS",
            "operation": "BUY",
            "coinValue": "821381.7",
            "amount": "0.5",
            "custodyFee": null,
            "status": "COMPLETED",
            "coinValueArs": "821381.70",
            "creationTime": "2023-12-16T20:11:19.539Z"
        }
    ])
    const { user } = useAuthContext();

    const loadOrders = async () => {
        try {

            const { data } = await mantecaApi.get(`/v1/user/${user.uid}/orders`)

            console.log(data)
        } catch (error) {
            console.log(error);
        }

    }


    useEffect(() => {
        // loadOrders()
    }, [])

    return (
        <>
            <DataTable
                data={orders}
                columns={[
                    { name: 'id', selector: row => row.orderId, sortable: true },
                    { name: 'Moneda', selector: row => row.coin, sortable: true },
                    { name: 'Operación', selector: row => row.operation, sortable: true },
                    { name: 'Cantidad', selector: row => row.amount, sortable: true },
                    { name: 'Estado', selector: row => row.status, sortable: true },
                    { name: 'Valor de la moneda en pesos argentinos', selector: row => row.coinValueArs, sortable: true },
                    { name: 'Fecha', selector: row => row.creationTime, sortable: true },
                ]}
                pagination
                fixedHeader
            />
        </>
    )
}
