import axios from 'axios';

const mantecaApi = axios.create({
    baseURL: process.env.REACT_APP_MANTECA_API_URL
});

mantecaApi.interceptors.request.use(config => {
    config.headers = {
        ...config.headers,
        'Accept': 'application/json',
        'md-api-key': process.env.REACT_APP_MANTECA_API_TOKEN,
        'Content-Type': 'application/json',
        'Company-Id': process.env.REACT_APP_MANTECA_COMPANY_ID  // Agregar el ID único al header
    }
    return config;
});

export default mantecaApi;
