import { createContext, useEffect, useState } from "react"

export const UIContext = createContext();




export const UIProvider = ({ children }) => {

    const [sidbarShow, setSidbarShow] = useState(false)

    return (
        <UIContext.Provider value={{
            sidbarShow,
            setSidbarShow
        }}>
            {children}
        </UIContext.Provider>
    )
}