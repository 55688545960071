import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import './App.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="946033150544-uv1it97e2as6t034a8lvt5l0el0pn5ji.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);
