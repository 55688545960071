import axios from 'axios';


const menleiApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});


menleiApi.interceptors.request.use(config => {

    config.headers = {
        ...config.headers,
        'Accept': 'application/json'
    }

    return config;
})

export default menleiApi;