import React, { useState } from 'react'
import { useAuthContext, useGoogleAuth } from '../hooks';
import { menleiApi } from '../api';
import Swal from 'sweetalert2';

export const Perfil = () => {

  const [tab, setTab] = useState('datos_personales');
  const { user } = useAuthContext()
  const { logOut } = useGoogleAuth();

  const changeTab = (e) => {
    e.preventDefault();
    document.querySelector(`[data-tab="${tab}"]`).classList.remove('border-bottom-purple', 'text-purple')
    setTab(e.target.dataset.tab);
    e.target.classList.add('border-bottom-purple', 'text-purple')
  }

  const handleClick = async e => {
    e.preventDefault()
    // console.log('eliminando cuenta...')
    try {
      
      const { isConfirmed } = await Swal.fire({
        title: '¿Estás seguro de borrar tu cuenta?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar'
      })
      
      if (isConfirmed) {
        await menleiApi.delete(`/user/${user.uid}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })

      }

      logOut()
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>

      <nav className='app__container app__container--transparent my-3 w-md-50'>
        <ol className="mb-0 list-group list-group-horizontal breadcrumbs__container">
          {/* el apartado seleccionado tiene esta propiedad aria-current="page" y esta clase active*/}
          <a
            href=""
            className="list-group-item d-flex align-items-center justify-content-center border-bottom-purple text-purple" data-tab='datos_personales'
            onClick={changeTab}
          >
            Datos personales
          </a>
          {/* <a
            href=""
            className="list-group-item d-flex align-items-center justify-content-center"
            data-tab='cuentas_guardadas'
            onClick={changeTab}
          >
            Cuentas Guardadas
          </a>
          <a
            href=""
            className="list-group-item d-flex align-items-center justify-content-center"
            data-tab='limites'
            onClick={changeTab}
          >
            Límites
          </a>
          <a
            href=""
            className="list-group-item d-flex align-items-center justify-content-center"
            data-tab='seguridad'
            onClick={changeTab}
          >
            Seguridad
          </a>
          <a
            href=""
            className="list-group-item d-flex align-items-center justify-content-center"
            data-tab='referidos'
            onClick={changeTab}
          >
            Referidos
          </a> */}
        </ol>
      </nav>

      {
        tab == 'datos_personales' && (
          <>

            <div className='app__container p-4 my-3 w-md-50'>
              <h5>Información</h5>
              <ul className="list-group ">
                <li className="list-group-item d-flex justify-content-between bg-gray">
                  <strong>Nombre</strong> <span>{user.name}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between bg-gray">
                  <strong>Email</strong> <span>{user.email}</span>
                </li>
              </ul>
            </div>

            <div className='p-4  text-center'>
              <button
                className='btn btn-outline-danger btn-sm'
                onClick={handleClick}
              >
                Eliminar cuenta
              </button>
            </div>

            <div className='text-center p-4'>
              <a className='text-purple me-1'>Copyright Menlei 2023</a>
              <a className='text-purple me-1'>Terminos y condiciones</a>
              <a className='text-purple me-1'>Politica de privacidad</a>
            </div>
          </>
        )
      }

      {/* {
        tab == 'cuentas_guardadas' && (
          <>
            <div className='app__container p-4 my-3'>
              <h5>No hay cuentas guardadas</h5>
              <p className=''>
                Recuerda que para poder realizar cualquier tipo de retiro es necesario que agregues a donde quieres enviar tu dinero
              </p>
              <div className='text-center'>

                <button className='btn btn-outline-purple '>Agregar cuenta</button>
              </div>
            </div>
          </>
        )
      } */}

    </>
  )
}
