import axios from "axios";
import { menleiApi } from "../api";
import { useAuthContext } from "./useAuthContext";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

export const useGoogleAuth = () => {

    const { onLogin, onChecking, startLogout } = useAuthContext();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            startGoogleLogin(codeResponse)
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const startGoogleLogin = (googleResponse) => {

        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${googleResponse.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then(async (res) => {

                onChecking();

                try {

                    const { data } = await menleiApi.post('/auth/register', {
                        email: res.data.email,
                        name: res.data.name,
                        google_id: res.data.id
                    })

                    localStorage.setItem('token', data.token);
                    localStorage.setItem('refresh_token', data.refresh_token);

                    const { user } = data;

                    onLogin({ name: user.name, uid: user.id });

                } catch (error) {
                    console.log(error)
                    startLogout()
                }

            })
            .catch((err) => console.log(err));

    }

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        startLogout();
    };

    return {
        logOut,
        startGoogleLogin,
        login,
    }
}