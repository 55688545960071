import { createContext, useEffect, useState } from "react"

export const AuthContext = createContext();

const userInitialState = {
    name: '',
    email: '',
    uid: '',
    status: 'checking'  // 'authenticated'; // 'not-authenticated'
}


export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(userInitialState);


    const resetUser = () => {
        setUser(userInitialState);
    }

    // useEffect(() => {
    //     console.log(user)
    // }, [user])


    return (
        <AuthContext.Provider value={{
            user,
            setUser,
            resetUser,
        }}>
            {children}
        </AuthContext.Provider>
    )
}