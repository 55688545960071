import React from 'react'
import { HashRouter } from 'react-router-dom';
import { AppRouter } from './routes/AppRouter';
import { AuthProvider } from './context/';


const App = () => {

  return (
    <AuthProvider>
      <HashRouter future={{ v7_startTransition: true }}>
        <AppRouter />
      </HashRouter>
    </AuthProvider>
  )
}

export default App;