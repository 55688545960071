import React, { useContext } from "react"
import { Link } from "react-router-dom"

import { UIContext } from '../../context';

export const Header = () => {


    const { setSidbarShow } = useContext(UIContext);

    return (

        <header
            className="navbar sticky-top bg-light flex-md-nowrap p-0"
            data-bs-theme="light"

        >
            <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" to="/">
                <img src="/img/LOGO-400PX.png"
                    className="img-fluid"
                />
            </Link>

            <ul className="navbar-nav flex-row d-md-none">
                <li className="nav-item text-nowrap">
                    <button
                        className="nav-link px-3"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#sidebarMenu"
                        aria-controls="sidebarMenu"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => setSidbarShow(state => !state)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                        </svg>
                    </button>
                </li>
            </ul>
        </header>

    )
}
