import React, { useEffect, useRef, useState } from 'react'
import { useAuthContext } from '../hooks';

export const Widget = () => {

  const [queryParameters, setQueryParameters] = useState({
    address: "",
    companyId: "66194c6fc2faf00007d4a59d",
    coin: "",
    network: "",
    ramp: "",
    userId: '',
    fiatAmount: "",
    cryptoAmount: "",
    successRedirectUrl: "https://menlei.net",
    externalId: "",
    hideChat: true
  });

  const [option, setOption] = useState()
  const [disabled, setDisabled] = useState(true)
  const [iframeSrc, setIframeSrc] = useState('');
  const { user } = useAuthContext();
  const form = useRef()

  useEffect(() => {
    if (user) {
      setQueryParameters(params => ({ ...params, userId: user.uid }))
    }
  }, [user])

  useEffect(() => {
    if (form.current) {
      setDisabled(false)
      form.current.querySelectorAll('input, select').forEach(element => {
        if (!element.value && element.hasAttribute('required')) {
          setDisabled(true)
        }
      })
    }
  }, [queryParameters, option])

  const handleChooseAction = e => {
    setQueryParameters(params => ({ ...params, ramp: e.target.dataset.action }))
  }

  const handleInput = e => {
    setQueryParameters(params => ({ ...params, [e.target.name]: e.target.value }))
  }

  const handleSubmit = e => {
    e.preventDefault();
    const url = new URL("https://ramp-qa.manteca.dev/auth/w-signup/id");
    option === 'pesos' ? delete queryParameters.cryptoAmount : delete queryParameters.fiatAmount;
    Object.keys(queryParameters).forEach(key => {
      if (queryParameters[key]) {
        if (key === 'cryptoAmount') {
          url.searchParams.append(key, queryParameters.cryptoAmount * (10 ** 18));
        } else {
          url.searchParams.append(key, queryParameters[key])
        }
      }
    });
    setIframeSrc(url.href);
  }

  const handleChangeOption = e => {
    setOption(e.target.value)
  }

  return (
    <>
      {
        !iframeSrc && (
          <div className=''>
            <div className='row justify-content-end'>
              <div className='mb-2 mb-md-0 col-md-3 d-flex justify-content-md-end justify-content-center'>
                <button
                  className={`btn btn--purple w-md-75 w-100 d-block ${queryParameters.ramp === 'on' ? 'btn--active' : ''}`}
                  data-action="on"
                  onClick={handleChooseAction}
                >
                  Comprar
                </button>
              </div>
              <div className='col-md-3 d-flex justify-content-md-start justify-content-center'>
                <button
                  className={`btn btn--purple w-md-75 w-100 d-block ${queryParameters.ramp === 'off' ? 'btn--active' : ''}`}
                  data-action="off"
                  onClick={handleChooseAction}
                >
                  Vender
                </button>
              </div>
            </div>
          </div>
        )
      }
      {
        queryParameters.ramp && !iframeSrc && (
          <div className='animate__animated animate__fadeIn'>
            <div className='row'>
              <div className='col-12'>
                <form onSubmit={handleSubmit} ref={form}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="network">Red a través de la cual se envía la cripto</label>
                    <select
                      name="network"
                      id="network"
                      className="form-select"
                      onChange={handleInput}
                      required
                    >
                      <option value="">--</option>
                      <option value="ETH">ETH</option>
                      <option value="BSC">BSC</option>
                      <option value="POLYGON">POLYGON</option>
                      <option value="OPTIMISM">OPTIMISM</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="address">Destino donde se desea recibir las cryptos (wallet)</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="form-control"
                      onInput={handleInput}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="coin">La moneda que se cambiará o depositará.</label>
                    <select
                      name="coin"
                      id="coin"
                      className="form-select"
                      onChange={handleInput}
                      required
                    >
                      <option value="">--</option>
                      <option value="USDT">USDT</option>
                      <option value="USDC">USDC</option>
                      <option value="WLD">WLD</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="option">¿Desea operar en pesos o en la coin?</label>
                    <select
                      name="option"
                      id="option"
                      className="form-select"
                      onChange={handleChangeOption}
                      required
                    >
                      <option value="">--</option>
                      <option value="coin">En criptos</option>
                      <option value="pesos">Con pesos</option>
                    </select>
                  </div>

                  {
                    option && (
                      option === 'pesos'
                        ? (
                          <div className="mb-3">
                            <label htmlFor="fiatAmount" className="form-label">Monto a operar en pesos</label>
                            <input
                              type="number"
                              id="fiatAmount"
                              name="fiatAmount"
                              value={queryParameters.fiatAmount}
                              className="form-control"
                              onInput={handleInput}
                              required
                            />
                          </div>
                        )
                        : (
                          <div className="mb-3">
                            <label htmlFor="cryptoAmount" className="form-label">Monto a operar de la coin</label>
                            <input
                              type="number"
                              id="cryptoAmount"
                              name="cryptoAmount"
                              value={queryParameters.cryptoAmount}
                              className="form-control"
                              onInput={handleInput}
                              required
                            />
                          </div>
                        )
                    )
                  }

                  <button
                    type="submit"
                    className="btn btn--purple"
                    disabled={disabled}
                  >
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </div>
        )
      }

      {
        iframeSrc && (
          <div className='app__container p-3 my-3'>
            <iframe
              src={iframeSrc}
              name="manteca_widget"
              title="Manteca Widget"
              id='manteca-iframe'
              className='w-100'
              style={{
                minHeight: '600px'
              }}
            ></iframe>
          </div>
        )
      }
    </>
  )
}

export default Widget
