import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { downloadCSV } from '../helpers';
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { mantecaApi } from '../api';

registerLocale('es', es)

export const Historial = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedRows, setSelectedRows] = useState([])
  const [data, setData] = useState([])
  const [orders, setOrders] = useState([]);

  const handlerOfSelectsChaging = ({ selectedRows: rows }) => {
    // console.log(rows)
    setSelectedRows(rows)
  };

  const handleClick = (e) => {
    if (selectedRows.length == 0) return;

    downloadCSV(selectedRows);
  }

  const load = async () => {
    try {

      const respons = [
        {
          "orderId": "210",
          "userId": "35",
          "coin": "ETH_ARS",
          "operation": "BUY",
          "coinValue": "821381.7",
          "amount": "0.5",
          "custodyFee": null,
          "status": "COMPLETED",
          "coinValueArs": "821381.70",
          "creationTime": "2023-12-16T20:11:19.539Z"
        },
        {
          "orderId": "312",
          "userId": "35",
          "coin": "USDT_ARS",
          "operation": "BUY",
          "coinValue": "821381.7",
          "amount": "0.5",
          "custodyFee": null,
          "status": "COMPLETED",
          "coinValueArs": "821381.70",
          "creationTime": "2023-12-16T20:11:19.539Z"
        },
        {
          "orderId": "13",
          "userId": "35",
          "coin": "ETH_ARS",
          "operation": "BUY",
          "coinValue": "821381.7",
          "amount": "0.5",
          "custodyFee": null,
          "status": "COMPLETED",
          "coinValueArs": "821381.70",
          "creationTime": "2023-12-16T20:11:19.539Z"
        },
      ]

      setOrders(respons);
      setData(respons);

    } catch (error) {
      console.log(error)
    }
  }



  const filterData = (e) => {
    e.preventDefault();

    const form = new FormData(e.target)

    console.log(form.get('date'))

    setData(
      orders
        .filter(order => {
          if (form.get('amount')) {
            return order.amount == form.get('amount')
          }
          return order;
        })
        .filter(order => {
          if (form.get('date')) {
            return moment(order.creationTime).format('yyyy-MM-d') == form.get('date')
          }

          return order;
        })
        .filter(order => {
          if (form.get('coin') && form.get('coin').length > 0) {
            return order.coin.substr(0, order.coin.indexOf('_')) == form.get('coin')
          }

          return order;
        })
    )

  }

  useEffect(() => {
    load()
  }, [])


  return (
    <>
      <div className=' py-4 my-3'>
        <h3>Historial</h3>
        <p className='mb-0'>En esta sección podés ver los movimientos que generaste</p>
      </div>

      <div className='app__container p-4 my-3'>
        <form onSubmit={filterData}>
          <div className="row mb-2">
            <div className="col-auto">
              <div className="mb-3 row">
                <label htmlFor="coin" className="col col-form-label">Moneda</label>
                <div className="col">
                  <select className='form-control' id='coin' name='coin'>
                    <option value=''>--</option>
                    <option value='USDT'>USDT</option>
                    <option value='USDC'>USDC</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="mb-3 row">
                <label htmlFor="operation" className="col col-form-label">Operación</label>
                <div className="col">
                  <select className='form-control' id='operation'>
                    <option value={''}>--</option>
                    <option value='SELL'>SELL</option>
                    <option value='BUY'>BUY</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="mb-3 row">
                <label htmlFor="amount" className="col col-form-label" >Cantidad</label>
                <div className="col-8">
                  <input id='amount' className='form-control' name='amount' />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="mb-3 row">
                <label htmlFor="status" className="col col-form-label">Estado</label>
                <div className="col">
                  <select className='form-control' id='status'>
                    <option>--</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="mb-3 row">
                <label htmlFor="date" className="col col-form-label">Fecha</label>
                <div className="col-8">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className='form-control'
                    locale="es"
                    name='date'
                    dateFormat='yyyy-MM-d'
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 48 48"
                      >
                        <mask id="ipSApplication0">
                          <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                            <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                            <path
                              fill="#fff"
                              d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                            ></path>
                          </g>
                        </mask>
                        <path
                          fill="currentColor"
                          d="M0 0h48v48H0z"
                          mask="url(#ipSApplication0)"
                        ></path>
                      </svg>
                    }
                  />
                </div>
              </div>
            </div>

          </div>
          <div>
            <button
              type="submit"
              className="btn btn--purple btn-sm"
            >
              Buscar
            </button>
          </div>
        </form>
      </div>

      <div className='app__container p-4 my-3'>
        <button
          className='btn btn--purple btn-sm'
          onClick={handleClick}
          disabled={selectedRows.length > 0 ? false : true}
        >
          Exportar
        </button>
        <DataTable
          data={data}
          columns={[
            { name: 'id', selector: row => row.orderId, sortable: true },
            { name: 'Moneda', selector: row => row.coin.substr(0, row.coin.indexOf('_')), sortable: true },
            { name: 'Operación', selector: row => row.operation, sortable: true },
            { name: 'Cantidad', selector: row => row.amount, sortable: true },
            { name: 'Estado', selector: row => row.status, sortable: true },
            { name: 'Valor de la moneda en pesos argentinos', selector: row => row.coinValueArs + ' $', sortable: true },
            { name: 'Fecha', selector: row => moment(row.creationTime).format('yyyy-MM-d'), sortable: true },
          ]}
          pagination
          fixedHeader
          selectableRows
          onSelectedRowsChange={handlerOfSelectsChaging}
        />
      </div>

    </>
  )
}
