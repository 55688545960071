import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import { useAuthStore } from "../hooks";
import { Auth, PrivateRoutes } from "./";
import { AuthContext } from "../context";
import { useAuthContext } from "../hooks/useAuthContext";

export const AppRouter = () => {

    const { user } = useContext(AuthContext)
    const { checkAuthToken } = useAuthContext()

    // const authStatus = 'not-authenticated'; // 'authenticated'; // 'not-autenticated'
    // const { status, checkAuthToken } = useAuthStore()


    useEffect(() => {
        checkAuthToken();
    }, [])


    if (user.status === 'checking') {
        return (
            <h3>Loading...</h3>
        )
    }

    return (
        <>
            <Routes>

                {
                    user.status === 'not-authenticated'
                        ? (
                            <>
                                <Route path="/auth/*" element={<Auth />} />
                                <Route path="*" element={<Navigate to='/auth/login' />} />
                            </>
                        )
                        : (
                            <>


                                <Route path="/*" element={<PrivateRoutes />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </>
                        )
                }
            </Routes>
        </>
    )

}