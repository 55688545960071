import React from 'react'
import { Orders, PrecioDelDolar, Widget } from '../components'
import { useAuthContext } from '../hooks'

export const Dashboard = () => {

    const { user } = useAuthContext();

    return (
        <>
            <div className='app__container p-4 my-3'>
                <h6 className='mb-4 fw-bold text-purple'>

                    Hola, {user.name}!
                </h6>
                <h2 className='mb-4 fw-bold'>
                    $ 0.00
                </h2>
                <Widget />
            </div>

            <PrecioDelDolar />

            <div className='app__container p-4 my-3'>
                <h5>Movimientos</h5>
                {/* <p className='mb-0'>Todavia no tenés ningun movimiento, apretá el boton comprar para generar el primero</p> */}
                <Orders />
            </div>
        </>
    )
}
